import * as t from 'io-ts'

const BrandDecoder = t.type(
  {
    name: t.string,
    logoUrl: t.string,
  },
  'Brand'
)

const PriceDecoder = t.type(
  {
    text: t.string,
    value: t.number,
  },
  'Price'
)

export const ProductPriceDecoder = t.intersection(
  [
    t.type(
      {
        upc: t.string,
        current: PriceDecoder,
        currency: t.string,
      },
      'IntersectProductPrice01'
    ),
    // Optional
    t.partial({ currencySpacer: t.string, previous: PriceDecoder }, 'IntersectProductPrice02'),
  ],
  'ProductPrice'
)

const TransitionLensDecoder = t.partial(
  {
    isEvolve: t.boolean,
    defaultType: t.string,
    defaultAlphaLevel: t.number,
    defaultColor: t.string,
    availableColors: t.array(t.string),
    availableClens: t.array(t.string),
  },
  'TransitionLens'
)

export type Brand = t.TypeOf<typeof BrandDecoder>

export type Price = t.TypeOf<typeof PriceDecoder>

export type ProductPrice = t.TypeOf<typeof ProductPriceDecoder>

export type TransitionLens = t.TypeOf<typeof TransitionLensDecoder>

export const ProductDecoder = t.intersection(
  [
    t.type(
      {
        upc: t.string,
        code: t.string,
        name: t.string,
        colorCode: t.string,
        thumbnailUrl: t.string,
        brand: BrandDecoder,
      },
      'IntersectProduct01'
    ),
    // Optional
    t.partial(
      {
        lensColorLabel: t.string,
        frameColorLabel: t.string,
        styleName: t.string,
        size: t.string,
        frameSizeSelection: t.array(t.string),
        category: t.string,
        isCustom: t.boolean,
        isTransition: t.boolean,
        transitionLens: TransitionLensDecoder,
        rxAvailable: t.boolean,
        isSelected: t.boolean,
        isProductNotAvailable: t.boolean,
        alternativeThumbnailUrl: t.string,
      },
      'IntersectProduct02'
    ),
  ],
  'Product'
)

export type Product = t.TypeOf<typeof ProductDecoder>

export interface Color {
  colorCode: string
  lensName?: string
  colorName?: string
  swatchImage?: string
  clen?: string
  lensIdentifier?: string // this param only use for One configurator integrator
}
